import {
  cloneElement,
  ComponentPropsWithoutRef,
  forwardRef,
  isValidElement,
} from 'react';
import {
  button,
  buttonContent,
  buttonRounded,
  buttonSize,
  buttonSpinner,
  buttonVariants,
} from './button.css';
import { clsx } from 'clsx';
import { Slot, Slottable } from '@radix-ui/react-slot';
import { Spinner } from '../spinner/spinner.tsx';
import { VisuallyHidden } from '../visually-hidden/visually-hidden.tsx';

export type ButtonProps = {
  inversed?: boolean;
  size?: 'small' | 'medium' | 'large';
  variant?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'quaternary'
    | 'critical'
    | 'warning';
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  asChild?: boolean;
  loading?: boolean;
  rounded?: boolean;
  contentClassName?: string;
} & ComponentPropsWithoutRef<'button'>;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  function ButtonComponent(
    {
      size = 'medium',
      variant = 'primary',
      className,
      contentClassName,
      children,
      inversed,
      startIcon,
      endIcon,
      asChild = false,
      loading = false,
      rounded = false,
      disabled,
      type = 'button',
      ...rest
    },
    ref,
  ) {
    const Component = asChild ? Slot : 'button';
    const childrenContent =
      asChild && isValidElement(children) ? children.props.children : children;

    const ButtonContent = (
      <>
        {/**
         * We need a wrapper to set `visibility: hidden` to hide the button content whilst we show the `Spinner`.
         * The button is a flex container with a `gap`, so we use `display: contents` to ensure the correct flex layout.
         *
         * However, `display: contents` removes the content from the accessibility tree in some browsers,
         * so we force remove it with `aria-hidden` and re-add it in the tree with `VisuallyHidden`
         */}
        <div
          className={clsx(buttonContent, contentClassName)}
          style={{ visibility: loading ? 'hidden' : 'visible' }}
          aria-hidden
        >
          {startIcon}
          <Slottable>{childrenContent}</Slottable>
          {endIcon}
        </div>
        <VisuallyHidden>
          {startIcon}
          <Slottable>{childrenContent}</Slottable>
          {endIcon}
        </VisuallyHidden>
        {loading && (
          <Spinner
            className={buttonSpinner}
            size={size === 'small' ? 16 : 20}
          />
        )}
      </>
    );

    return (
      <Component
        ref={ref}
        data-inversed={inversed ? '' : undefined}
        className={clsx(
          button,
          buttonSize[size],
          buttonVariants[variant],
          rounded && buttonRounded,
          className,
        )}
        disabled={disabled || loading}
        type={type}
        {...rest}
      >
        {/**
         * https://github.com/radix-ui/primitives/issues/1825
         * Slot does not work with wrapper around content, so we need to use cloneElement()
         */}
        {asChild && isValidElement(children)
          ? cloneElement(children, children.props, ButtonContent)
          : ButtonContent}
      </Component>
    );
  },
);
